<template>
  <div>
    <el-select
      class="max-fill"
      v-if="format==='dropDown'"
      size="medium"
      multiple
      placeholder="请选择选项"
      v-model="checks"
    >
      <el-option
        v-for="(op, index) in option.split(',')"
        :key="index"
        :label="op"
        :value="index"
        >{{ op }}</el-option
      >
    </el-select>
    <el-checkbox-group v-else v-model="checks">
      <el-checkbox v-for="(op, index) in option.split(',')" :key="index" :label="op" :value="index">{{
        op
      }}</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "MultipleSelect",
  props: {
    format: {
      type: String,
      default: 'dropDown',
    },
    option: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checks: [],
    };
  },
};
</script>
